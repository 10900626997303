import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Aos from "aos";
import 'aos/dist/aos.css';

const About = () => {

        useEffect(() => {
            Aos.init({ duration: 900 });
        }, []);
    return (
        <div>
            <Container>
                <h3 className="text-center mt-5 fw-bold" style={{ fontFamily: "cursive" }}>About Us</h3>
                <Row className="d-flex justify-content-center">
                    <Col md={10} >
                        <p data-aos = "fade-up" style={{fontFamily:"monospace"}} className="text-center fs-6 fw-bolder mt-5 shadow-lg p-3 mb-5 bg-body-tertiary rounded p-5">Welcome to Comfort Cottage, your serene home stay nestled near Varanasi's Banaras Railway Station. Offering premium quality service, Comfort Cottage ensures a cozy and comfortable stay with well-appointed rooms designed for your relaxation and peace of mind.At Comfort Cottage, hospitality is our hallmark. From the warm welcome at check-in to the attentive service throughout your stay, our dedicated staff ensures that every moment is enjoyable and stress-free. Whether you are visiting for spiritual exploration or cultural immersion, our tranquil ambiance provides the perfect retreat after a day of exploring the ancient city.To further enhance your experience, we offer the exclusive "Smiles for Miles" taxi service, making your local travel convenient and hassle-free. Explore Varanasi's bustling streets, serene ghats, and vibrant markets with ease, knowing that our reliable transportation service is at your disposal.Discover comfort, convenience, and heartfelt hospitality at Comfort Cottage - your home away from home in Varanasi.</p>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5">
                    <Col md={4} data-aos = "fade-right">
                        <Image src={require("../../Images/Room1.jpg")} fluid rounded />
                    </Col>
                    <Col md={4} data-aos = "fade-left">
                        <Image src={require("../../Images/Room2.jpg")} fluid rounded />
                    </Col>
                </Row>
                <Row className="d-flex justify-content-around mt-5 mb-5">
                    <Col md={4} data-aos = "fade-right">
                        <Image src={require("../../Images/Room3.jpg")} fluid rounded />
                    </Col>
                    <Col md={4} data-aos = "fade-left">
                        <Image src={require("../../Images/Smiles.jpg")} fluid rounded />
                    </Col>
                </Row>
            </Container>

        </div>
    )
}


export default About;