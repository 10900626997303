import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

const Service = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center flex-column">
                            <h3 className="text-center mt-5 mb-5">Our Services</h3>
                            <p className="text-center mt-3 mb-3 fs-4 fw-medium">Smiles For Miles Travels</p>
                            <Image src={require("../../Images/Smiles.jpg")} rounded />
                            <p className="text-center shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 fw-bold fs-5">Smiles for Miles Travels in Varanasi brings the essence of the holy city alive with exceptional service and unforgettable experiences. With a stellar 4.5+ star rating, our satisfied customers revel in personalized attention and expertly curated tours that showcase Varanasi's cultural richness. Discover the heart of spirituality with us, where every journey is crafted to exceed expectations and leave a lasting smile on your face.</p>
                            <p></p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default Service;