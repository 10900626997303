import React, { useState } from "react";
import "../../Styles/footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

const Footer = () => {
    const [showStory, setShowStory] = useState(false);
    const [showTeam, setShowTeams] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [refund, setRefund] = useState(false);
    // const auth = localStorage.getItem("user");
    return (
        <div>
            <div className="footer">
                <Container>
                    <Row>
                        <Col md={3}>
                            <div>
                                <h3 style={{ fontFamily: "cursive" }}>Comfort Cottage</h3>
                                <p>Bringing you closer to your dream home, one click at a time.</p>
                            </div>
                        </Col>
                        <Col md={3} className="d-flex justify-content-center">
                            <div>
                                <h3>About Us</h3>
                                <ul>
                                    <li> <Link className="footer_Links" onClick={(() => setShowStory(true))} >Our Story</Link></li>
                                    <li> <Link className="footer_Links" onClick={(() => setShowTeams(true))}>Our Teams</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} className="d-flex justify-content-center">
                            <div>
                                <h3>Support</h3>
                                <ul>
                                    <li> <Link className="footer_Links" onClick={(() => setCancel(true))} > Cancel Booking</Link></li>
                                    <li> <Link className="footer_Links" onClick={(() => setRefund(true))} > Refund Policy</Link></li>
                                    {/* <li> {auth ?<Link className="footer_Links" to="/login_Profile">Home</Link> :<Link className="footer_Links" to="/owner_Login">Owner Login</Link> } </li> */}
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} className="d-flex justify-content-center">
                            <div>
                                <h3>Find Us</h3>
                                <ul>
                                    {/* <li> <Link className="footer_Links" to="/reviews"> Reviews</Link></li> */}
                                    <li> <Link className="footer_Links" onClick={(() => window.open("https://itsmenaveenpandey.wixsite.com/smilesformiles"))}> Smiles Travels</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="foot">
                Copywright <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-c-circle" viewBox="0 0 16 16">
                    <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512" />
                </svg> Comfort Cottage 2024
            </div>

            {/* Show Story Modal */}
            <Modal show={showStory} onHide={(() => setShowStory(false))}>
                <Modal.Header closeButton>
                    <Modal.Title>Our Story</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-bold fs-6">Welcome to Comfort Cottage, a tranquil homestay nestled in the heart of the city, just 2km away from Banaras Railway Station. Our homestay offers a peaceful retreat amidst the bustling cityscape of Varanasi, where guests can unwind and immerse themselves in the local culture and charm. With comfortable accommodations, warm hospitality, and convenient proximity to key attractions, Comfort Cottage is your perfect gateway to experiencing the vibrant essence of Varanasi. Whether you're here for spiritual exploration or cultural discovery, our homestay promises a memorable stay enriched with authentic hospitality and modern comforts.</p>
                </Modal.Body>
            </Modal>

            {/* Show Teams */}

            <Modal show={showTeam} onHide={(() => setShowTeams(false))}>
                <Modal.Header closeButton>
                    <Modal.Title>Our Teams</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="fw-medium">Welcome to Comfort Cottage Homestay, where our team of dedicated hosts ensures your experience is as comfortable and enjoyable as possible. With a passion for hospitality and a commitment to guest satisfaction, our team members bring warmth and expertise to every aspect of your stay. Whether it's providing cozy accommodations, sharing local insights, or ensuring your needs are met with care, our team is here to make your visit memorable and relaxing. Discover the charm of Comfort Cottage Homestay with a team that takes pride in creating a welcoming atmosphere for all our guests.</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Show Refund  */}

            <Modal show={refund} onHide={(() => setRefund(false))}>
                <Modal.Header closeButton>
                    <Modal.Title>Refund Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-semibold text-center fs-6" >Refund Policy is If Cancellations is Processed within 24 Hrs of the Check In Date No Refund will be processed. If Refund is processed before 48 Hrs of Check In Date then full Refund will be processed.</p>
                </Modal.Body>
            </Modal>

            {/* Cancel Booking */}

            <Modal show={cancel} onHide={(() => setCancel(false))}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Bookings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center fw-semibold">For Booking Cancellations please Go to Contact section and talk with our Owners. With full Transparency and as per Refund Policy your Money will be refunded back to your account.</p>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Footer;