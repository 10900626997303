import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import axios from "axios";
import { baseURL } from "../../../baseUrl";
import { useNavigate } from "react-router-dom";



const Booking = () => {
    const checkInDate = localStorage.getItem("Indate");
    const checkOutDate = localStorage.getItem("Outdate");
    const room = localStorage.getItem("Rooms").split(',')
    const amount = ((new Date(checkOutDate) - new Date(checkInDate)) / 86400000) * 1500;
    const [room_id,setroom_id] = useState("");
    const [name,setName] =useState("");
    const [address,setAddress] =useState("");
    const [adhar_card_no,setAdhar_Card_No] =useState("");
    const [mobile_no,setMobile_No] =useState("");
    const [payment_status,setPayment_Status] =useState("");
    const [guest_List,setGuest_List]= useState("");
    const [zone,setZone] =useState("");
    const [state,setState] =useState("");
    const [pincode,setPincode] =useState("");
    const navigate = useNavigate();
    const handle_Submit = () =>{
        setAddress(zone+state+pincode)
        axios({
            method:"POST",
            url:`${baseURL}/api/v1/booking/add_Coustomer_details`,
            data:({room_id,name,address,adhar_card_no,mobile_no,checkInDate,checkOutDate,amount,payment_status,guest_List}),
            headers:{
                "Content-Type":"application/json"
            }
        }).then((res)=>{
            if (res.data.statusCode === 200) {
                alert("Room details filled Successfully")
                navigate("/");
            }
        }).catch((err)=>{
            const message = err.message;
            const code = message.substring(message.length - 3);
            if (code==="500") {
                alert("Internal Server Error")
            } else if (code ==="400") {
                alert("Required Fields")
            } else if (code==="409") {
                alert("Same Booking Already Exists")
            } else {
                alert("Error")
            }
        })
    }
    return (
        <div>
            <Container>
                <h3 className="text-center mt-5 mb-5">Booking Details</h3>
                <Row className="d-flex justify-content-center mt-5 mb-5">
                    <Col className="text-center" md={6}>
                    <Image  src={require("../../Images/Payments.jpg")} alt="..." fluid height="300px" width="300px"/>
                    <p className="fw-bolder mb-5">Please Pay the Full Amount and Send it to Owner at Contact Number 9473838188 after this Payment only your booking will be Confirmed.</p>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="text" placeholder="Name" onChange={((e)=>setName(e.target.value))}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Mobile No</Form.Label>
                                    <Form.Control type="number" placeholder="Mobile Number" onChange={((e)=>setMobile_No(e.target.value))}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Adhar Card No</Form.Label>
                                    <Form.Control type="Number" placeholder="Adhar Card Number" onChange={((e)=>setAdhar_Card_No(e.target.value))}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="Number" placeholder={`₹ ${amount}/-`} disabled />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Check In Date</Form.Label>
                                    <Form.Control type="Number" placeholder={checkInDate} disabled />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Check Out Date</Form.Label>
                                    <Form.Control type="Number" placeholder={checkOutDate} disabled />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formGridAddress1">
                                <Form.Label>Guest List</Form.Label>
                                <Form.Control placeholder="Guest Names" onChange={((e)=>setGuest_List(e.target.value))}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress2">
                                <Form.Label>Address</Form.Label>
                                <Form.Control placeholder="Address" onChange={((e)=>setZone(e.target.value))}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formGridAddress2">
                                <Form.Label>Transaction Id</Form.Label>
                                <Form.Control placeholder="Transaction Id" onChange={((e)=>setPayment_Status(e.target.value))}/>
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control onChange={((e)=>setState(e.target.value))}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control onChange={((e)=>setPincode(e.target.value))}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Room No</Form.Label>
                                    <Form.Select defaultValue="Choose" onChange={((e)=>setroom_id(e.target.value))}>
                                        <option value="Choose" disabled hidden>Choose</option>
                                        {
                                            room.map((items) =>
                                                <option>{items}</option>
                                            )
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <div className="text-center mt-5 mb-5">
                                <Button variant="primary" type="button" onClick={handle_Submit}>Submit</Button>
                            </div>
                            <p className="fw-bold">Note :- After Payment Only this form Filling will be valid .</p>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Booking;