import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './Assests/Components/Navbar/navbar.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Assests/Components/Home/home.jsx';
import Footer from './Assests/Components/Footer/footer.jsx';
import Service from './Assests/Components/Services/service.jsx';
// import Login from './Assests/Components/Login/login.jsx';
// import Profile from './Assests/Components/Profile/profile.jsx';
import Review from './Assests/Components/Reviews/review.jsx';
import About from './Assests/Components/About_Us/about.jsx';
import Contact from './Assests/Components/Contact_Us/contact.jsx';
// import ChangePassword from './Assests/Components/Profile/changePassword.jsx';
import Booking from './Assests/Components/Home/booking.jsx';
// import ChangeReview from './Assests/Components/Reviews/changeReview.jsx';
import ScrollToTop from './Assests/Components/PrivateComponents/ScrollToTop.jsx';
// import PrivateComponents from './Assests/Components/PrivateComponents/privatecomponents.jsx';
// import Delete_Booking from './Assests/Components/Home/deleteBooking.jsx';


function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' Component={Home}></Route>
        <Route path='/Services' Component={Service}></Route>
        <Route path='/reviews' Component={Review}></Route>
        <Route path='/About_Us' Component={About}></Route>
        <Route path='/Contact_Us' Component={Contact}></Route>
        {/* <Route path='/owner_Login' Component={Login}></Route> */}
        <Route path='/book_Room' Component={Booking}></Route>
        {/* Private Component */}
        {/* <Route element={<PrivateComponents />}>
          <Route path='/login_Profile' Component={Profile}></Route>
          <Route path='/change_Password' Component={ChangePassword}></Route>
          <Route path='/get_reviews' Component={ChangeReview}></Route>
          <Route path='/check_Bookings' Component={Delete_Booking}></Route>
        </Route> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
