import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";


const Contact = () => {
    return (
        <div>
            <Container>
                <Row className="d-flex justify-content-between mt-5">
                    <Col className="mb-5 mt-5" md={3}>
                        <Image src={require("../../Images/Image 1.jpg")} fluid rounded />
                    </Col>
                    <Col className="shadow-lg p-3 mb-5 bg-body-tertiary rounded" style={{alignContent:"center"}} md={5}>
                        <h4 className="text-center fw-bold">Naveen Pandey</h4>
                        <p className="text-center fw-medium">Co-Owner of this Homestay </p>
                        <p className="text-center fw-bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg> : 9473838188</p>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                    <Col className="mb-5" md={3}>
                        <Image src={require("../../Images/Image 2.jpg")} fluid rounded />
                    </Col>
                    <Col className="shadow-lg p-3 mb-5 bg-body-tertiary rounded" style={{alignContent:"center"}} md={5}>
                        <h4 className="text-center fw-bold">Mayank Pandey</h4>
                        <p className="text-center fw-medium">Co-Owner of this Homestay </p>
                        <p className="text-center fw-bold"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg> : 9682891006</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default Contact;