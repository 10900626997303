import React, { useState } from "react"
import "../../Styles/navbar.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { baseURL } from "../../../baseUrl";


const Header = () => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [review, setReview] = useState("");

    const post_review = () => {
        axios({
            method: "POST",
            url: `${baseURL}/api/v1/reviews/add_Reviews`,
            data: ({ email, name, review })
        }).then((res) => {
            if (res.data.statusCode===200) {
                alert("Review Posted")
                setShow(false)
            }
        }).catch((err) => {
            const message = err.message;
            const code = message.substring(message.length - 3);
            if (code===400) {
                alert("Required Fields")
            }else{
                alert("Error");
            }
        })
    }
    return (
        <div className="header_Body">
            <Navbar expand="lg">
                <Container >
                    <Navbar.Brand style={{ fontWeight: "900", fontFamily: "cursive", fontSize: "1.7rem", margin: "0" }} href="/">Comfort Cottage</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto Navbar_Link">
                            <Nav.Link id="Navbar_Links" active href="/">Home</Nav.Link>
                            <Nav.Link id="Navbar_Links" active href="/Services">Services</Nav.Link>
                            <Nav.Link id="Navbar_Links" active href="/Contact_Us">Contact</Nav.Link>
                            <Nav.Link id="Navbar_Links" active href="/About_Us">About Us</Nav.Link>
                        </Nav>
                        <div className="d-flex justify-content-end">
                            {/* <Button style={{ backgroundColor: "#2B1B12" }} onClick={(() => setShow(true))}>Review</Button> */}
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Modal show={show} onHide={(() => setShow(false))}>
                <Modal.Header closeButton>
                    <Modal.Title>Reviews</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                autoFocus
                                onChange={((e) => setEmail(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                autoFocus
                                onChange={((e) => setName(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"                        >
                            <Form.Label>Example textarea</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Write Review here ...." onChange={((e) => setReview(e.target.value))} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={post_review}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Header;