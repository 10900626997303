import React, { useEffect, useState } from "react";
import "../../Styles/review.css";
import axios from "axios";
import { baseURL } from "../../../baseUrl";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
const Review = () => {
    const [data, setData] = useState([]);
    const [check, setCheck] = useState(false);

    const getReview = () => {
        axios({
            method: "GET",
            url: `${baseURL}/api/v1/reviews/veiw_Reviews`
        }).then((res) => {
            setData(res.data.data)
            setCheck(true)
        }).catch((err) => {
            setCheck(false)
        })
    }

    useEffect(() => {
        getReview();
    }, [])

    return (
        check ? <div>
            <Container className="mb-5">
                <Row>
                    <h3 className="text-center mt-3 mb-3">Reviews Given By Our Coustomer</h3>
                    {
                        data.map((item) =>
                            <Col md={3}>
                                <div className="review_box">
                                   <p className="fw-bold text-center">{item.review}</p>
                                   <p className="text-end" style={{fontSize:"small",marginRight:"2px"}}>Posted By : {item.name}</p>
                                   <p className="text-start fw-medium" style={{marginLeft:"2px"}}>{item.updatedAt.substring(0,10)}</p>
                                </div>
                            </Col>
                        )
                    }
                </Row>
            </Container>

        </div> :
            <div style={{ minHeight: "90vw" }}>
                <h1 className="text-center mt-5" style={{ fontFamily: "sans-serif" }}>No Review Till Now</h1>
            </div>
    )

}

export default Review;