import React from "react"
import "../../Styles/home.css"
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
// import axios from "axios";
// import { baseURL } from "../../../baseUrl";
// import { useNavigate } from "react-router-dom";
// import FadeLoader from "react-spinners/FadeLoader";

const Home = () => {
    // const date = new Date();
    // const [checkInDate, setcheckInDate] = useState("");
    // const [checkOutDate, setcheckOutDate] = useState("");
    // const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();
    // let today_date = `${date.getFullYear()}`
    // if (date.getMonth() < 10) {
    //     today_date = `${today_date}-0${date.getMonth() + 1}`
    // } else {
    //     today_date = `${today_date}-${date.getMonth() + 1}`
    // }

    // if (date.getDate() < 10) {
    //     today_date = `${today_date}-0${date.getDate()}`
    // } else {
    //     today_date = `${today_date}-${date.getDate()}`
    // }
    // today_date = today_date.trim();
    // const check_Availability = () => {
    //     setLoading(true);
    //     axios({
    //         method: "POST",
    //         url: `${baseURL}/api/v1/room/check_Availability`,
    //         data: ({ checkInDate, checkOutDate })
    //     }).then((res) => {
    //         if (res.data.statusCode === 200) {
    //             alert("Room Available")
    //             setLoading(false);
    //             localStorage.setItem("Rooms", res.data.data);
    //             localStorage.setItem("Indate", checkInDate)
    //             localStorage.setItem("Outdate", checkOutDate)
    //             navigate("/book_Room")
    //         }
    //     }).catch((err) => {
    //         setLoading(false);
    //         alert("Room Not Available");
    //     })
    // }
    return (
        <div>
            <div style={{ backgroundColor: "#FBF5F1" }}>
                <Container>
                    <Row className="d-flex justify-content-between pb-5">
                        <Col md={3}>
                            <h1>Find a Best Place in Varanasi for Homestay</h1>
                            <p className="fw-semibold" style={{ fontSize: "small", marginTop: "2rem" }}>Discover the perfect homestay in Varanasi for a cozy, home-like experience. Located centrally, it comfortably accommodates guests, ensuring a warm ambiance akin to home. Enjoy the convenience and comfort of this ideal retreat in Varanasi.</p>
                        </Col>
                        <Col md={7} >
                            <Image src={require("../../Images/temple.png")} fluid rounded />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row style={{ display: "flex", justifyContent: "center", marginTop: "-3rem" }}>
                    <Col md={8} className="search_bar">
                        <p className="fw-bold text-center mt-3">For Booking Contact <br /> Naveen Pandey : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                        </svg> <Button onClick={(()=>window.open(`tel: ${9473838188}`))} style={{backgroundColor:"#DDC7BB",color:"black"}} >9473838188</Button> <br /> Mayank Pandey :<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg> <Button onClick={(()=>window.open(`tel: ${9682891006}`))} style={{backgroundColor:"#DDC7BB",color:"black"}}>9682891006</Button>  <br /> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                            </svg> : itsmecomfortcottage.invaranasi@gmail.com</p>
                        {/* <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label className="fw-bold" style={{ fontFamily: "monospace" }} >Check In</Form.Label>
                                    <Form.Control className="fw-bold" style={{ fontFamily: "monospace" }} type="date" min={today_date} onChange={((e) => setcheckInDate(e.target.value))} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label className="fw-bold" style={{ fontFamily: "monospace" }} >Check Out</Form.Label>
                                    <Form.Control className="fw-bold" style={{ fontFamily: "monospace" }} type="date" min={checkInDate ? checkInDate : today_date} onChange={((e) => setcheckOutDate(e.target.value))} />
                                </Form.Group>
                                <Form.Group as={Col} style={{ textAlign: "center", marginTop: "auto" }} controlId="formGridZip">
                                    <Button style={{ backgroundColor: "#2B1B12" }} onClick={check_Availability}>Check Availability</Button>
                                </Form.Group>
                            </Row>
                        </Form> */}
                    </Col>
                </Row>
                {/* <div className="d-flex justify-content-center mt-2"><FadeLoader loading={loading} /></div> */}

            </Container>
            <Container>
                <Row>
                    <Col>
                        <h1 className="text-center fw-bold" style={{ marginTop: "2rem", fontFamily: "serif" }}>Why choose Us</h1>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col md={5} >
                        <p className="text-center fw-bolder" >Elevating Your Home Staying Experience with Integrity, and Unmatched Personalized Service</p>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between mt-5 mb-5">
                    <Col md={6}>
                        <Image src={require("../../Images/Self_home.jpg")} fluid rounded />
                    </Col>
                    <Col md={5}>
                        <div>
                            <h1 className="text-start mb-5">We help you to find your desired Homestay</h1>
                            <p className="mb-5 fw-bolder">From cozy rooms to luxurious amenities, our dedicated stay guides you through every step of the journey in exploring Varanasi and Outstations, ensuring your experience while tarvelling becomes commendable</p>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between" >
                    <Col md={3} className="d-flex justify-content-center mb-4" >
                        <div className="choice_Block">
                            <div className="Icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="#2B1B12" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                </svg>
                            </div>
                            <h3>Location</h3>
                            <p>We are located in the heart of city, With 2Km away from Banaras Railway Station.</p>
                        </div>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center mb-4" >
                        <div className="choice_Block">
                            <div className="Icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-person-video3" viewBox="0 0 16 16">
                                    <path d="M14 9.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-6 5.7c0 .8.8.8.8.8h6.4s.8 0 .8-.8-.8-3.2-4-3.2-4 2.4-4 3.2" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h5.243c.122-.326.295-.668.526-1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v7.81c.353.23.656.496.91.783Q16 12.312 16 12V4a2 2 0 0 0-2-2z" />
                                </svg>
                            </div>
                            <h3>Personalized Service</h3>
                            <p>Our services adapt to your unique needs, making your stay stress-free and safe.</p>
                        </div>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center mb-4" >
                        <div className="choice_Block">
                            <div className="Icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-clipboard2" viewBox="0 0 16 16">
                                    <path d="M3.5 2a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H12a.5.5 0 0 1 0-1h.5A1.5 1.5 0 0 1 14 2.5v12a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-12A1.5 1.5 0 0 1 3.5 1H4a.5.5 0 0 1 0 1z" />
                                    <path d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5" />
                                </svg>
                            </div>
                            <h3>Transparency</h3>
                            <p>Our charges are Transperent and No hidden charges or any charges to increase the amount.</p>
                        </div>
                    </Col>
                    <Col md={3} className="d-flex justify-content-center mb-4" >
                        <div className="choice_Block">
                            <div className="Icons">
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-telephone-inbound-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0" />
                                </svg>
                            </div>
                            <h3>Customer Support</h3>
                            <p>Providing peace of mind with our responsive and attentive customer service.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <h1 className="text-center fw-bold mt-5 mb-5" style={{ fontFamily: "sans-serif" }}>Our Rooms</h1>
            <Container className="p-0">
                <Row className="d-flex justify-content-around m-0 mb-5">
                    <Col md={4} className="p-0 d-flex justify-content-center">
                        <div className="rooms_Details">
                            <Image src={require("../../Images/Room1.jpg")} fluid rounded />
                            <p>
                                <ul>
                                    <li className="mt-3 fw-medium" >Air Conditioning,Television,Wardrobe,Electric Kettle,Bed Linen,Room Sofa,Free Wifi.(Room 1) </li>
                                    <li className="mt-3 fw-medium" ><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                    </svg>2000/Night </li>
                                    <li className="mt-3 fw-medium" >250 Sqft</li>
                                    <li className="mt-3 fw-medium" >Private Washroom</li>
                                    <li className="mt-3 fw-medium" >Kitchen Facility Avaliable</li>
                                </ul>
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="p-0 d-flex justify-content-center">
                        <div className="rooms_Details">
                            <Image src={require("../../Images/Room2.jpg")} fluid rounded />
                            <p>
                                <ul>
                                    <li className="mt-3 fw-medium" >Air Conditioning,Television,Wardrobe,Electric Kettle,Bed Linen,Room Sofa,Free Wifi.(Room 2)</li>
                                    <li className="mt-3 fw-medium" ><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                    </svg>2000/Night </li>
                                    <li className="mt-3 fw-medium" >250 Sqft</li>
                                    <li className="mt-3 fw-medium" >Private Washroom</li>
                                    <li className="mt-3 fw-medium" >Kitchen Facility Avaliable</li>
                                </ul>
                            </p>
                        </div>
                    </Col>
                    <Col md={4} className="p-0 d-flex justify-content-center">
                        <div className="rooms_Details">
                            <Image src={require("../../Images/Room3.jpg")} fluid rounded />
                            <p>
                                <ul>
                                    <li className="mt-3 fw-medium" >Air Conditioning,Television,Wardrobe,Electric Kettle,Bed Linen,Room Sofa,Free Wifi.(Room 3)</li>
                                    <li className="mt-3 fw-medium" ><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-currency-rupee" viewBox="0 0 16 16">
                                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                                    </svg>2000/Night </li>
                                    <li className="mt-3 fw-medium" >250 Sqft</li>
                                    <li className="mt-3 fw-medium" >Private Washroom</li>
                                    <li className="mt-3 fw-medium" >Kitchen Facility Avaliable</li>
                                </ul>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="review_Box">
                <Row className="d-flex justify-content-center">
                    <Col md={2}>
                        <h3 className="text-center fw-semibold">What People Say About Us</h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="review_line">
                        <div className="reviews"><Image src={require("../../Images/review (1).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/review (2).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/review (3).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/review (4).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/review (5).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (1).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (2).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (3).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (4).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (5).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (6).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (7).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (8).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (9).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (10).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (12).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (13).jpg")} fluid /></div>
                        <div className="reviews"><Image src={require("../../Images/coustomer_Image (14).jpg")} fluid /></div>
                    </Col>
                    <h6 className="text-center mt-5 mb-5 fs-1 fw-bolder">These are Some Google Reviews and Valuable Guests Moments at our Homestay Comfort Cottage.</h6>
                </Row>
            </Container>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center flex-column" md={6}>
                        <h4 className="text-center">How to Reach Us</h4>
                        <iframe className="d-flex justify-content-center mb-1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.516529265308!2d82.97267357516662!3d25.28684427765336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e334a10dce8db%3A0x833331591f010080!2sSmiles%20for%20Miles%20Travels!5e0!3m2!1sen!2sin!4v1720770893400!5m2!1sen!2sin" title="Location" frameborder="0" width="fit-content" height="450" style={{ border: "2px solid black" }}></iframe>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Home;